import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import './App.css';
import HomePage from './HomePage';
import ProjectsPage from './ProjectsPage';
import EEPage from './EEPage';

const logo = '/logo.png';
const github = '/images/github.png'
const CV = '/CV Muhammad Haaris Khan Lunasa 24.pdf';

function NavBarDir ({activePage, isMobileDevice}) {

		return (
			<ul className={`${isMobileDevice ? 'mobile-nav-items' : ''}`}>
				<li>
					<Link to="/home">
						<button className={activePage === '/home' ? 'active' : activePage === '/' ? 'active' : ''}>
							Home
						</button>
					</Link>
				</li>
				<li>
					<Link to="/projects">
						<button className={activePage === '/projects' ? 'active' : ''}>
							Projects
						</button>
					</Link>
				</li>
				<li>
					<Link to="/experience-education">
						<button className={activePage === '/experience-education' ? 'active' : ''}>
							Experience & Education
						</button>
					</Link>
				</li>
			</ul>
		);
}

function NavBarLinks ({isMobileDevice}) {
		return (
			<ul className={`${isMobileDevice ? 'mobile-nav-items' : ''}`}>
				<li>
					<a href="https://www.linkedin.com/in/muhammad-haaris-khan" target="_blank" rel="noopener noreferrer">
						<button className='link'><b>in</b></button>
					</a>
				</li>
				<li>
					<a href="https://github.com/Strikewind" target="_blank" rel="noopener noreferrer">
						<button className='link'>
							<img src={ github } className="link-img"/>
						</button>
					</a>
				</li>
				<li>
					<a href={CV} target="_blank" rel="noopener noreferrer">
						<button className='link'>CV</button>
					</a>
				</li>
				<li>
					<a href="mailto:mhaariskhan1@gmail.com" target="_blank" rel="noopener noreferrer">
						<button className='link'>Contact ✉</button>
					</a>
				</li>
			</ul>
		);
}

function NavBar({activePage, setActivePage, isMobileDevice}) {

	const path = useLocation();

	useEffect(() => {
		setActivePage(path.pathname);
	}, [path]);

	useEffect(() => {
		if (activePage === '/') {
			document.body.style.overflow = 'hidden';
		}
		else {
			document.body.style.overflow = 'unset';
		}
	}, [activePage]);

	return (
		<nav className='box nav'>
			<div className='left'>
				<Link to="/home">
					<img src={logo} className="App-logo"/> 
				</Link>
				<NavBarDir {...{activePage, isMobileDevice}} />
			</div>
			<div className='right'>
				<NavBarLinks {...{isMobileDevice}} />
			</div>
		</nav>
	);
}

function MobileNavBar({activePage, setActivePage, isMobileDevice, toggle, setToggle}) {

	const path = useLocation();

	useEffect(() => {
		setActivePage(path.pathname);
	}, [path]);

	useEffect(() => {
		if (activePage === '/') {
			document.body.style.overflow = 'hidden';
		}
		else {
			document.body.style.overflow = 'unset';
		}
	}, [activePage]);

	return (
		<nav className={`box mobile-nav ${toggle ? 'open' : 'closed'}`} onClick={() => setToggle(!toggle)}>
			<NavBarDir {...{activePage, isMobileDevice}} />
			<NavBarLinks {...{isMobileDevice}} />
		</nav>
	);
}

function Popup() {

	return (
		<div className='bg-blur'>
			<div className='vertical-stack popup'>
				<div className='box white popup'>
					<p className='title'>Not cookies, just some info...</p>
					<div className='vertical-stack popup'>
						<p className='subtitle-on-white'><b><br/>Original Content Ahead!</b><br/></p>
						<p className='subtitle-on-white popup'>
							This website was made entirely from scratch with React.js and CSS, without the use of any templates.<br/>
						</p>
						<p className='subtitle-on-white popup'>
							Try it on both desktop and mobile!<br/><br/>
						</p>
					</div>
					<div className='horizontal-stack popup'>
						<Link to="/home">
							<button className='button popup'>Proceed</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}


function App() {

	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
	
	const [toggle, setToggle] = useState(false);
	const [activePage, setActivePage] = useState('Home');

	useEffect(() => {
		if (toggle) {
			document.body.style.overflow = 'hidden';
		}
		else {
			document.body.style.overflow = 'unset';
		}
	}, [toggle]);


	useEffect(() => {
		window.scrollTo(0, 0);
	  }, [activePage]);


	useEffect(() => {
		if (!isMobileDevice) {
			const handleScroll = () => {
			const background = document.querySelector('.App-bg');
			const scrollPosition = window.scrollY;
			background.style.backgroundPositionY = `${-scrollPosition * 0.05}px`;
			};
		
			window.addEventListener('scroll', handleScroll);
			return () => {
			window.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	return (
		<Router>
			{activePage === '/' ? <Popup /> : <></>}
			<div className='div-page'>
				<div className='App-bg'/>
				{isMobileDevice ? 	<MobileNavBar {...{activePage, setActivePage, isMobileDevice, toggle, setToggle}} /> : 
									<NavBar {...{activePage, setActivePage, isMobileDevice, toggle, setToggle}} />}
				{isMobileDevice ?  <Link to="/home"><img src={logo} className="App-logo mobile"/></Link> : <></> }
				{isMobileDevice ?  <p className='hamburger'>☰</p> : <></> }
				<div className='box-container-main'>
					<div className='box main'>
						<Routes>
							<Route exact path="/" element={<HomePage />} />
							<Route path="/home" element={<HomePage />} />
							<Route path="/projects" element={<ProjectsPage />} />
							<Route path="/experience-education" element={<EEPage />} />
						</Routes>
					</div>
				</div>
			</div>
		</Router>
	);
}

export default App;
