import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const profile = "/images/profile.png";
const wordcloud = "/images/wordcloud.png";
const GIF = "/videos/portfolio-gif.gif";
const grid = "/grid.png";
const riccardoprofile = "/images/riccardo-profile.png";
const hadrienprofile = "/images/hadrien-profile.png"
const bernhardprofile = "/images/bernhard-profile.png"

function HomePage() {

	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

	useEffect(() => {
		document.title = 'Home – Muhammad Haaris Khan';
	  }, []);

	return (
		<div className='vertical-stack'>
			<div className='box blank wide'>
				<div className='horizontal-stack'>
					<img src={ profile } className="profile"/>
					<div className={`vertical-stack ${isMobileDevice ? '' : 'left'}`}>
						<h1 className='title'>Muhammad Haaris Khan</h1>
						<p className='jobtitle'>Computer Vision Engineer at Lúnasa | First Class Honours from Imperial College London | Dean's List 2022 & 2024</p>
						<img src={ wordcloud } className='wordcloud'/>
					</div>
				</div>
			</div>
			<div className='box-container reverse'>
				<div className='vertical-stack-full'>
					<div className='box blue big right'>
						<div className='gif-mask'>
							<Link to="/projects">
								<img src={ grid } className="grid"/>
								<img src={ GIF } className="gif"/>
							</Link>
							<div className='gif-text'>View Projects</div>
						</div>
						<div className='vertical-stack text'>
							<h1 className='quote'><i>"Quality, Quantity,<br/> &nbsp; and Everything Else..."</i></h1>
							<h1 className='quote2'><i>"Quality, Quantity,<br/> &nbsp; and Everything Else..."</i></h1>
							<p className='text-on-blue absolute-fix'>
							For some, seeing is believing. For me, it's a powerful ability we can now extend to our digital counterparts. 
							I have experience in platforms such as Pytorch, which I use to develop and innovate until the tough problems become solved ones.
							My background includes academic research, such as improving zero-shot video generation, 
							as well as practical applications like long-range 3D pose estimation.
							My goal is to harness the power of machine learning to help build the future in this age of exponential technologies.<br/><br/>
							</p>
						</div>
					</div>
					<div className='box white'>
						<h2 className='heading-on-white'><b>21ˢᵗ Century Skills</b></h2>
						<div className='horizontal-stack'>
							<span className='gold left'></span>
							<span className='horizontal-blank'></span>
						</div>
						<p className='text-on-white'>
							<b>Critical Thinking/Problem Solving</b><br/>
							<ul>
								<li>Designing and programming software to solve problems optimally</li>
								<li>Designed viral puzzle game levels</li>
							</ul>
							<b>Creativity</b><br/>
							<ul>
								<li>UI Design</li>
								<li>3D modelling and CAD</li>
								<li>Image and video editing freelance</li>
							</ul>
							<b>Collaboration</b><br/>
							<ul>
								<li>Worked with different departments within Avio for post-flight analysis and systems engineering</li>
								<li>Worked with people from over 60 countries in a global project of digitally recreating Earth</li>
							</ul>
							<b>Communication</b><br/>
							<ul>
								<li>Chosen to lead a discussion with Sir Anthony Seldon about the role of AI in the future of education</li>
							</ul>
						</p>
					</div>
				</div>
				<div className='vertical-stack thinner'>
					<div className='box white shorter'>
						<div className='testimonial-container'>
							<p className='text-on-white'>
								<i>
								"As his thesis supervisor, I can attest to his ability to overcome obstacles in machine learning and computer vision. 
								The project introduced innovative methods to enhance video generation, yielding considerable academic and practical relevance. 
								These novel approaches, which were also prepared for a peer-reviewed conference submission, 
								showcase his ability to deliver high-quality technical solutions and contribute significantly to the field. 
								The thesis goes way beyond what would have been expected for a final year project."
								</i>
							</p>
							<img src={ bernhardprofile } className="testimonial-profile"/>
							<p className='text-on-white'>
								<b>Bernhard Kainz</b><br/>
								Professor at FAU Erlangen-Nürnberg and Reader in Medical Image Computing at Imperial College London
							</p>
						</div>
					</div>
					<div className='box white shorter'>
						<div className='testimonial-container'>
							<p className='text-on-white'>
								<i>
								"Muhammad demonstrated outstanding creativity, dedication, and communication skills throughout his entire Master Thesis project. 
								This lead to state-of-the-art work in understanding diffusion model latent spaces and how to leverage image diffusion models 
								for time-consistent video generation at zero-cost."
								</i>
							</p>
							<img src={ hadrienprofile } className="testimonial-profile"/>
							<p className='text-on-white'>
								<b>Hadrien Reynaud</b><br/>
								PhD student in AI for Healthcare at Imperial College London
							</p>
						</div>
					</div>
					<div className='box white shorter'>
						<div className='testimonial-container'>
							<p className='text-on-white'>
								<i>
								"Haaris worked with me during his internship in AVIO and did a fantastic job with the project he was assigned, 
								finishing it well in advance and with a great final result. 
								He is very talented at working out and finding solutions to complicated problems, is hard working, well organized and responsible.
								Great job!"
								</i>
							</p>
							<img src={ riccardoprofile } className="testimonial-profile"/>
							<p className='text-on-white'>
								<b>Riccardo Pelle</b><br/>
								Avionic Systems Engineer at AVIO
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HomePage;